import './App.css';
// import Yoseph from './images/yoseph-tamene.jpg';
import ExperienceBlock from './components/ExperienceBlock';
import experience from './data/experience.json';
import projects from './data/projects.json';
import Slide from './components/Slide';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const SectionHeader = ({ children }) => (<h3 className='text-4xl text-center font-bold my-5'>{children}</h3>)

function App() {
  return (
    <div className="h-full bg-fixed bg-gradient-to-tl from-gray-700 via-gray-900 to-black">
      {/* NAVBAR? Maybe */}

      {/* Intro/About Section */}
      <div className='flex md:flex-row flex-col md:justify-between justify-center p-6 h-screen'>
        <div className='md:w-1/3 flex justify-center md:justify-end items-center'>
          {/* Image */}
          <img className='w-48 md:w-80 rounded-full drop-shadow-lg shadow-white' src='/images/yoseph-tamene.jpg' alt="Yoseph Tamene" />
        </div>
        <div className='md:w-2/3 rounded-md shadow-sm md:pr-32 md:my-auto'>
          {/* Name + Typing Titles */}
          <h1 className='text-center text-6xl md:text-8xl font-extrabold '>Yoseph Tamene</h1>
          <h2 className='text-center text-xl font-bold mt-2 uppercase'>Software Engineer | Web Developer | Tech Enthusiast</h2>
        </div>
      </div>

      {/* Past Work */}
      <div className='p-6'>
        <SectionHeader>Work Experience</SectionHeader>
        <div className='flex flex-wrap justify-center'>
          {experience.map((exp, idx) => <ExperienceBlock key={idx} exp={exp} />)}
        </div>
      </div>

      {/* Past Projects */}
      <div className='p-6'>
        <SectionHeader>Past Projects</SectionHeader>
        <Carousel stopOnHover infiniteLoop useKeyboardArrows emulateTouch showStatus={false} showThumbs={false}>
          {projects.map((project, idx) => <Slide key={idx} project={project} />)}
        </Carousel>
      </div>

      {/* Contact Section */}
      <div>
        <SectionHeader>Let's Connect</SectionHeader>
        <h3 className='text-lg text-center px-3'>To get in touch or if you have any questions, reach out through any of the following!</h3>
        <div className='flex justify-center space-x-10 my-5'>
          <a href='https://linkedin.com/in/yosephtamene' target="blank">
            <img src="/images/linkedin.svg" className="w-16 hover:opacity-90 hover:cursor-pointer" alt="LinkedIn Logo" />
          </a>
          <a href='https://github.com/tameney22' target="blank">
            <img src="/images/github.svg" className="w-16 hover:opacity-90 hover:cursor-pointer" alt="GitHub Logo" />
          </a>
          {/* <img src="" className="w-16" alt={imgAlt} /> */}

        </div>
      </div>

      {/* Footer */}
      <footer className='my-5 text-center'>&copy; Copyright {new Date().getFullYear()}, Yoseph Tamene</footer>
    </div>
  );
}

export default App;
