import React from "react";

// const Skill = ({ txt }) => (<span className='bg-white rounded-md text-blue-900 font-semibold shadow-sm mr-2 mb-1 px-1 py-0.5 text-sm'>{txt}</span>)

// const Slide = ({ exp: { img, imgAlt, company, position, skills } }) => {
//   return (
//     <div className=' w-1/2 snap-center flex flex-nowrap border-2 border-white mr-10 mb-5 rounded-md hover:shadow-2xl shadow-slate-200 ease-in duration-200'>
//       <div className='flex divide-x w-96 h-32'>
//         <div className='flex align-middle justify-center w-1/3'>
//           <img src={img} className="w-16" alt={imgAlt} />
//         </div>
//         <div className='px-3 py-1 w-2/3'>
//           <h4 className='font-semibold text-xl'>{company}</h4>
//           <h5 className='text-lg'>{position}</h5>
//           <div className='flex flex-wrap mt-1'>
//             {skills.map((txt, idx) => <Skill txt={txt} />)}
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }

const Slide = ({ project: { projectTitle, desc, url, location } }) => {
  return (
    <div className="border-2 h-56 border-white rounded-lg md:w-2/5 mx-auto mb-10 shadow-lg py-1 px-2 text-left">
      <h4 className="text-2xl font-bold">{projectTitle}</h4>
      <h5 className="italic">{location}</h5>
      <p className="overflow-y-auto h-24">{desc}</p>
      <div className="flex justify-center mt-3">
        {url && (
          <a
            className="bg-white px-4 py-1 text-black rounded font-bold hover:opacity-90 hover:cursor-pointer"
            href={url}
            target="blank"
          >
            More Information
          </a>
        )}
      </div>
    </div>
  );
};

export default Slide;
