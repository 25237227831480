import React from 'react';

const Skill = ({ txt }) => (<span className='bg-white rounded-md text-blue-900 font-semibold shadow-sm mr-2 mb-1 px-1 py-0.5 text-sm'>{txt}</span>)

const ExperienceBlock = ({ exp: { img, imgAlt, company, position, skills, duration } }) => {
  return (
    <div className='w-5/6 md:w-2/5 border-2 border-white mx-5 rounded-md hover:shadow-2xl shadow-slate-200 ease-in duration-200 mb-5'>
      <div className='flex divide-x '>
        <div className='flex align-middle justify-center w-1/3'>
          <img src={img} className="w-16" alt={imgAlt} />
        </div>
        <div className='px-3 py-1 w-2/3'>
          <h4 className='font-semibold text-xl'>{company}</h4>
          <h5 className='text-lg'>{position}</h5>
          <h6 className='text-sm italic'>{duration}</h6>
          <div className='flex flex-wrap mt-1'>
            {skills.map((txt, idx) => <Skill key={idx} txt={txt} />)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExperienceBlock